import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, FieldArray } from 'redux-form'
import { InfoButton } from '../../../../../../common/components'
import { SelectField, RadioGroupDisgusedAsButtons } from '../../../../../../common/components/ReduxFormField'
import * as _ from 'lodash'
import Travellers from '../../../Travellers'
import { Row } from 'react-bootstrap'

export class TravellersTab extends Component {

  getGroupIcon(name) {
    switch (name) {
      case 'Individual':
        return { icon: 'user', customIconSet: false };
      case 'Couple':
        return { icon: 'icon-couple', customIconSet: true };
      case 'Family':
        return { icon: 'icon-family', customIconSet: true };
      case 'Group':
        return { icon: 'icon-group', customIconSet: true };
      default:
        return { icon: 'user', customIconSet: false };
    }
  }

  render () {
    const { currentProduct, formValues } = this.props
    const tripType = () => _.get(formValues, 'data.attributes.metadata.scheme_type')
    const travellerGroup = _.get(formValues, 'data.attributes.metadata.traveller_group')

    const groupTypes = _.map(_.get(currentProduct, `data.attributes.metadata.group_type_trip_${tripType()}`, {}), ((name, key) => {
      const { icon, customIconSet } = this.getGroupIcon(name)
      return {
        label: name,
        value: key,
        icon: icon,
        customIconSet: customIconSet
      }
    }))

    const definitions = _.get(currentProduct, 'data.attributes.metadata.definitions', {})
    const extendedFamily = _.get(currentProduct, 'data.attributes.metadata.extended_family', false)
    const childrenMaxAge = _.get(currentProduct, 'data.attributes.metadata.children.max_age')

    const residency = _.get(formValues, 'data.attributes.metadata.residency')

    if (residency === 'republic of ireland') {
      _.set(formValues, 'data.attributes.metadata.adults[0].address.country', 'IE')
    }

    const cloned = _.get(formValues, 'data.attributes.metadata.cloned', false);

    const childDisplayGroups = ['family'];
    const enableAddingChildrenOnProducts = ['SALT', 'SALTOM', 'ETIU', 'ETIE', 'ETIEG', 'ETIEES', 'LLOR', 'BST'];
    if (tripType() === 'single' && enableAddingChildrenOnProducts.includes(currentProduct.data.attributes.product_code)) {
      childDisplayGroups.push('group');
    }
    if (currentProduct.data.attributes.product_code === 'BST') {
      childDisplayGroups.push('individual');
    }

    return (
      <div>
        <div className='form-horizontal'>
          <Row className='group-types'>
            <Field
              name='data.attributes.metadata.traveller_group'
              label='Group Type'
              labelSize={2}
              infoButton={<InfoButton content={definitions.group_type} />}
              mdFieldSize={3}
              component={RadioGroupDisgusedAsButtons}
              options={groupTypes}
            />
          </Row>
        </div>

          <div style={!travellerGroup ? { display: 'none' } : {}}>
            <div className='form-horizontal'>
              <FieldArray
                name='data.attributes.metadata.adults'
                formValues={_.get(formValues, 'data.attributes.metadata.adults')}
                travellerGroup={_.get(formValues, 'data.attributes.metadata.traveller_group')}
                extendedFamily={extendedFamily}
                noTravellers={{
                  adults: _.get(formValues, 'data.attributes.metadata.adults', []).length,
                  children: _.get(formValues, 'data.attributes.metadata.children', []).length
                }}
                leadAddress
                change={this.props.change}
                component={Travellers}
                cloned={cloned}
                currentProduct={currentProduct}
            />
            </div>
            <div className='form-horizontal'>
              <FieldArray
                name='data.attributes.metadata.children'
                formValues={_.get(formValues, 'data.attributes.metadata.children')}
                travellerGroup={_.get(formValues, 'data.attributes.metadata.traveller_group')}
                noTravellers={{
                  adults: _.get(formValues, 'data.attributes.metadata.adults', []).length,
                  children: _.get(formValues, 'data.attributes.metadata.children', []).length
                }}
                displayGroups={childDisplayGroups}
                child
                component={Travellers}
                childrenMaxAge={childrenMaxAge}
                currentProduct={currentProduct}
            />
            </div>
          </div>

      </div>
    )
  }
}

const FORM_NAME = 'LeisureTravelForm'
const form = reduxForm({ form: FORM_NAME })(TravellersTab)
const mapStateToProps = (state, props) => {

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: getFormValues(FORM_NAME)(state),
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product
  }
}

export default connect(mapStateToProps)(form)
